import React, { useState, useEffect } from "react";
import Axios from "axios";
import  { useParams } from "react-router";
import { BrowserRouter as Route, Link } from 'react-router-dom';
import moment from 'moment';

function Project() {
	const {id} = useParams();
	const [projectDetails, setProjectDetails] = useState([]);
	const [getBlob, getBlobData] = useState([]);
	useEffect(()=> {
		if(id){
			Axios.get(`/projectdetails/${id}`).then((response)=>{
				console.log(response.data[0]);
				setProjectDetails(response.data[0]);
				
				const buf = new Buffer(response.data[0].description.data).toString();
				getBlobData(buf);
				
				/*var d = new Date('2019-04-09T18:30:00.000Z');
				console.log(d.getUTCHours()); // Hours
				console.log(d.getUTCMinutes());
				console.log(d.getUTCSeconds());*/
			});
		}
	}, [id]);
	
	return <>
		<section className="our_solution">
			<div className="container">
				<div className="row">
					<div className="col-md-12 col-sm-12 col-12">
						<div className="section-title text-center">
							<h2>Project Details</h2>
							<p>The majority have suffered alteration in some form, by injected humour, or randomised. by injected humour, or randomised.</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4 col-sm-4 col-12 projectsLeft">
						<Link to={`../uploads/portfolio/thumb/${projectDetails.filename}`} title={projectDetails.name}>
							<img src={`../uploads/portfolio/thumb/${projectDetails.filename}`} alt="Project"/>
						</Link>
					</div>
					<div className="col-md-8 col-sm-8 col-12 projectsRight">
						<h6>{projectDetails.name}</h6>
						<p><span><b>Start From:</b>{moment(new Date(projectDetails.start_date)).format("DD/MM/YYYY")}</span><span><b>To:</b>{moment(new Date(projectDetails.end_date)).format("DD/MM/YYYY")}</span></p>
						<p>{projectDetails.short_desc}</p>
						<p><b>Recomended from:</b>{projectDetails.recomend_from}</p>
						<p><b>Client Name:</b>{projectDetails.client_details}</p>
						<p><b>Project Budget:</b>{(projectDetails.budget==='' ? 'NA' : projectDetails.budget)}</p>
						<p dangerouslySetInnerHTML={{__html: getBlob, }} />
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Project;
