import React, { useState, useEffect } from "react";
import Axios from "axios";
import moment from 'moment';
import StarRatings from 'react-star-ratings';

function Client() {
	
	const [clientSpeakList, setClientSpeakList] = useState([]);
	
	const [visible, setVisible] = useState(4);
	
	const loadMoreItems = () => {
		setVisible((prevValue) => prevValue+4);
	};
	
	useEffect(()=> {
		Axios.get('/client-speak-List/').then((response)=>{
			//console.log(response.data);
			setClientSpeakList(response.data);
		});
	}, []);
	
	return <>
		<section className="client_say">
			<div className="container">
				<div className="row">
					<div className="col-md-12 col-sm-12 col-12 wow fadeInUp">
						<div className="section-title text-center">
							<h2>Client Say</h2>
							<p>The majority have suffered alteration in some form, by injected humour, or randomised. by injected humour, or randomised.</p>
						</div>
					</div>
				</div>
				<div className="row">
					{clientSpeakList.slice(0, visible).map((value, keyval) => {	
						return <>
							<div className="col-md-12 col-sm-12 col-12">
								<div className="clientBG">
									<div className="row">
										<div className="col-md-3 clientPic">	
											<img src={`../uploads/client/thumb/${value.filename}`} alt={value.filename}/>
										</div>
										<div className="col-md-9 clientText">
											<h6>{value.title}</h6>
											<div className="ratingSection">
												<StarRatings rating={value.rating} starDimension="18px" starSpacing="2px" starRatedColor="#ef512c" />
												<span>{value.rating}</span>
												<small>{moment(new Date(value.feedback_date)).format("DD/MM/YYYY")}</small>
											</div>
											<p>{value.tag}</p>
										</div>
									</div>
									<div className="client_desc">
										<p>{value.comment}</p>
									</div>
									<div className="client_person">
										<h6>{value.name}</h6>
										<p>Recommend from - {value.recomend_from}</p>
									</div>
								</div>
							</div>
						</>;	
					})}
				</div>
				<div className="">
					{visible < clientSpeakList.length && (
						<div className="col-md-12 col-lg-12 col-sm-12 col-12">
							<div className="showMore showMoreBtn"><button onClick={loadMoreItems}>Show More</button></div>
						</div>
					)}
					
				</div>
			</div>
		</section>
	</>;
}

export default Client;
