import OwlCarousel from 'react-owl-carousel';
import OwlCarousel1 from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import { BrowserRouter as Router, Routes, Route, NavLink, Link } from 'react-router-dom';

import React, { useState, useEffect } from "react";
import Axios from "axios";

const clientsay = {
	margin: 0,
	loop: true,
	responsiveClass: true,
	nav: false,
	dots: false,
	autoplay: true,
	navText: false,
	autoplayTimeout: 5000,
	smartSpeed: 500,
	autoplayHoverPause: true,
	responsive: {
		0: {
			items: 1,
		},
		605: {
			items: 2,
		},
		805: {
			items: 3,
		}
	},
};
const variousproducts = {
	margin: 10,
	loop: true,
	responsiveClass: true,
	nav: false,
	dots: false,
	autoplay: true,
	navText: false,
	autoplayTimeout: 2500,
	smartSpeed: 1500,
	autoplayHoverPause: true,
	responsive: {
		0: {
			items: 1,
		},
		355: {
			items: 2,
		},
		455: {
			items: 3,
		},
		600: {
			items: 4,
		},
		700: {
			items: 5,
		},
		855: {
			items: 6,
		},
		991: {
			items: 7,
		}
	},
};

function Home() {
	const [sliderList, setSliderList] = useState([]);
	const [serviceList, setServiceList] = useState([]);
	const [projectList, setProjectList] = useState([]);
	const [speakLists, setSpeakLists] = useState([]);
	const [clientSpeakList, setClientSpeakList] = useState([]);
	useEffect(()=> {
		Axios.get('/slider/').then((response)=>{
			//console.log('BaseUrl',process.env.REACT_APP_SLIDER_SERVER_URL);
			setSliderList(response.data);
		});
	}, []);
	useEffect(()=> {
		Axios.get('/services/').then((response)=>{
			//console.log(response.data);
			setServiceList(response.data);
		});
	}, []);
	useEffect(()=> {
		Axios.get('/projects/').then((response)=>{
			//console.log(response.data);
			setProjectList(response.data);
		});
	}, []);
	useEffect(()=> {
		Axios.get('/client-speak/').then((response)=>{
			//console.log(response.data);
			setSpeakLists(response.data);
		});
	}, []);
	useEffect(()=> {
		Axios.get('/client-speak/').then((response)=>{
			//console.log(response.data);
			setClientSpeakList(response.data);
		});
	}, []);
		
	return <>
		<OwlCarousel items={1} className="owl-theme myCarousel" loop nav autoplay={true} autoplayTimeout={4500} smartSpeed={1000} autoplayHoverPause={true}>
		{sliderList.map((val, key) => {
			return <><div className="item"><img width="100" height="525" src={`../uploads/slider/${val.filename}`} className="d-block w-100" alt={val.filename}/></div></>;
		})}
		</OwlCarousel>
		<section className="fullstackTop">
			<div className="container">
				<div className="row">
					<div className="col-md-12 col-sm-12 col-12">
						<div className="section-title text-center">
							<h2>Our Fullstack Devolopment Service</h2>
							<p>The majority have suffered alteration in some form, by injected humour, or randomised. by injected humour, or randomised.</p>
						</div>
					</div>
				</div>
				<div className="row fullstack_dsign">
						{serviceList.map((serviceval, servicekey) => {
							return <>
								<div className="col-md-4 col-sm-4 col-12 fullstack_bottom">
									<div className="fullstack_section">
										<img src={`../uploads/services/${serviceval.filename}`} alt="2"/>
										<div className="fullstack_box_section">
											<h6>{serviceval.name}</h6>
											<p>{serviceval.description}</p>
										</div>
									</div>
								</div>
							</>;
						})}
				</div>
				<div className="row">
					<div className="col-md-12 col-lg-12 col-sm-12 col-12">
						<div className="showMore showMoreBtn"><NavLink to="/services" className="atag_btn">Show More</NavLink></div>
					</div>
				</div>
			</div>
		</section>
		<section className="our_solution">
			<div className="container">
				<div className="row">
					<div className="col-md-12 col-sm-12 col-12">
						<div className="section-title text-center">
							<h2>Our Solution</h2>
							<p>The majority have suffered alteration in some form, by injected humour, or randomised. by injected humour, or randomised.</p>
						</div>
					</div>
				</div>
				<div className="row">
					{projectList.map((projectval, projectkey) => {
						return <>
							<div className="col-md-3 col-lg-3 col-sm-6 col-12 our_solution_part">
								<Link to={`/details/${projectval.id}`}>
									<div className="team-wrap">
										<div className="team-img">
											<img src={`../uploads/portfolio/${projectval.filename}`} alt={projectval.filename}/>
										</div>
										<div className="team-content">
											<div className="team-info">
												<h3>{projectval.name}</h3>
												<p>{projectval.short_desc}</p>
												<ul>
													<li>{projectval.client_details}</li>
												</ul>
											</div>
										</div>
									</div>							
								</Link>
							</div>
						</>;
					})}						
					<div className="col-md-12 col-lg-12 col-sm-12 col-12">
						<div className="showMore showMoreBtn"><NavLink to="/portfolio" className="atag_btn">Show More</NavLink></div>
					</div>
				</div>
			</div>
		</section>
		<section className="client_say">
			<div className="container">
				<div className="row">
					<div className="col-md-12 col-sm-12 col-12 wow fadeInUp">
						<div className="section-title text-center">
							<h2>Client Say</h2>
							<p>The majority have suffered alteration in some form, by injected humour, or randomised. by injected humour, or randomised.</p>
						</div>
					</div>
				</div>
				<div className="row">
					<OwlCarousel items={3} className="owl-theme" loop nav autoplay={true} autoplayTimeout={5000} smartSpeed={500} autoplayHoverPause={true} {...clientsay}>
					{speakLists.map((val, key) => {
						return <>
							<div className="item">
								<div className="service-wrap">
									<div className="service-content">
										<h3>{val.title}</h3>
										<div className="client_rating">
											<i className="fas fa-star"></i>
											<i className="fas fa-star"></i>
											<i className="fas fa-star-half-alt"></i>
											<i className="far fa-star"></i>
											<i className="far fa-star"></i>
										</div>
										<p><i className="fas fa-quote-left"></i>{val.comment}<i className="fas fa-quote-right"></i></p>
									</div>
								</div>
							</div>
						</>;
					})}
					</OwlCarousel>
					<div className="col-md-12 col-lg-12 col-sm-12 col-12">
						<div className="showMore showMoreBtn"><NavLink to="/client-speak" className="atag_btn">Show More</NavLink></div>
					</div>
				</div>
			</div>
		</section>
		<section>
			<div className="container slider_known">
				<OwlCarousel className='owl-theme' {...variousproducts}>
					<div className="item"><img src="../uploads/known_work/drupal.png" alt="drupal"/></div>
					<div className="item"><img src="../uploads/known_work/laravel.png" alt="laravel"/></div>
					<div className="item"><img src="../uploads/known_work/joomla.png" alt="joomla"/></div>
					<div className="item"><img src="../uploads/known_work/magento.png" alt="magento"/></div>
					<div className="item"><img src="../uploads/known_work/angularjs.png" alt="angularjs"/></div>
					<div className="item"><img src="../uploads/known_work/mongodb.png" alt="mongodb"/></div>
					<div className="item"><img src="../uploads/known_work/expressjs.png" alt="expressjs"/></div>
					<div className="item"><img src="../uploads/known_work/nodejs.png" alt="nodejs"/></div>
					<div className="item"><img src="../uploads/known_work/react.png" alt="react"/></div>
				</OwlCarousel>
			</div>
		</section>
		
		
	</>;
}

export default Home;
