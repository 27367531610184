import React, { useState, useEffect } from "react";
import Axios from "axios";
import  { useParams } from "react-router";
import { BrowserRouter as Route, Link } from 'react-router-dom';
function Project() {
	const {id} = useParams();
	
	const [projectList, setProjectList] = useState([]);
	const [visible, setVisible] = useState(4);
	
	const loadMoreItems = () => {
		setVisible((prevValue) => prevValue+4);
	};
	
	useEffect(()=> {
		if(id){
			Axios.get(`/projectlist/${id}`).then((response)=>{
				//console.log(response.data);
				setProjectList(response.data);
			});
		}else{
			Axios.get('/projectlist/').then((response)=>{
				//console.log(response.data);
				setProjectList(response.data);
			});
		}
	}, [id]);
	
	return <>
		<section className="our_solution">
			<div className="container">
				<div className="row">
					<div className="col-md-12 col-sm-12 col-12">
						<div className="section-title text-center">
							<h2>Our Solution</h2>
							<p>The majority have suffered alteration in some form, by injected humour, or randomised. by injected humour, or randomised.</p>
						</div>
					</div>
				</div>
				<div className="row projectsTop">
					{projectList.slice(0, visible).map((val, key) => {
						return <>
							<div className="col-md-3 col-lg-3 col-sm-6 col-12 projects_top">							
							<Link to={`/details/${val.id}`}>
								<div className="team-wrap">
									<div className="team-img">
										<img src={`../uploads/portfolio/thumb/${val.filename}`} alt={val.filename}/>
									</div>
									<div className="team-content">
										<div className="team-info">
											<h3>{val.name}</h3>
											<p>{val.short_desc}</p>
											<ul>
												<li>{val.client_details}</li>
											</ul>
										</div>
									</div>
								</div>
							</Link>	
							</div>
						</>;
					})}		
					{visible < projectList.length && (
						<div className="col-md-12 col-lg-12 col-sm-12 col-12">
							<div className="showMore showMoreBtn"><button onClick={loadMoreItems}>Show More</button></div>
						</div>
					)}
				</div>
			</div>
		</section>
	</>;
}

export default Project;
