import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/font-awesome/css/all.css';
import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import { BrowserRouter as Router, Routes, Route, NavLink, Link } from 'react-router-dom';
import Marquee from "react-fast-marquee";

import Contactus from './components/Contact-us';
import Home from './components/Home';
import Projects from './components/Projects';
import Services from './components/Services';
import Client from './components/Client';
import About from './components/About';
import Details from './components/Project-details';
import Meta from './components/Meta';
import ScrollButton from './components/Scroll-top-button';
import { Content, Heading } from './components/Scroll-top-style';

import { Helmet } from "react-helmet";

Axios.defaults.baseURL = 'http://esol.exaltedsolution.com/';
Axios.defaults.headers.post['Content-Type'] = 'application/json';
Axios.defaults.headers.post['Accept'] = 'application/json';
Axios.defaults.withCredentials = true;
// Axios.defaults.baseURL = 'http://localhost:3001/';

function App() {
	const currDate = new Date();
	const currYear = currDate.getFullYear();
	
	/*const [url, seturl] = useState(window.location.href);
	
	const loadCallBack = () => {
		//console.log(window.innerHeight);
		//alert(1+window.innerHeight);
		//alert(document.getElementById('App').clientHeight);
		seturl(document.getElementById('App').clientHeight);
		console.log(url);
	}
	useEffect(() => {
		//const loadCallBack = window.addEventListener("load", () => {
			//console.log('test rajesh');
			//alert(window.innerHeight);
			//alert(document.getElementById('header-area').clientHeight);
			//alert(document.getElementById('App').clientHeight);
			//alert(document.getElementById('footer-area').clientHeight);
		//});
		window.addEventListener("load", loadCallBack);
		return () => {
			window.removeEventListener("load", loadCallBack);
		};
	}, [url]);*/
	
	const [isOpen, setisOpen] = useState(false);
	const onClickMenu = () => {
		setisOpen(!isOpen);
		
	}
	const [technologyList, setTechnologyList] = useState([]);
	const [siteSetup, setSitesetupData] = useState([]);
	const [socialData, setSocialData] = useState([]);

	useEffect(()=> {
		Axios.get('/technologies/').then((response)=>{
			//console.log(response.data);
			setTechnologyList(response.data);
		});
	}, []);
	useEffect(()=> {
		Axios.get('/sitesetup/').then((response)=>{
			//console.log(response.data);
			setSitesetupData(response.data[0]);
		});
	}, []);
	useEffect(()=> {
		Axios.get('/social/').then((response)=>{
			//console.log(response.data);
			setSocialData(response.data);
		});
	}, []);
	useEffect(() => {
		const header = document.getElementById("myHeader");
		const sticky = header.offsetTop;
		const scrollCallBack = window.addEventListener("scroll", () => {
			if (window.pageYOffset > sticky) {
				header.classList.add("headerScrollSticky");
			} else {
				header.classList.remove("headerScrollSticky");
			}
		});
		const handleResize = window.addEventListener("resize", () => {
			if (window.innerWidth > 991) {
				setisOpen(!isOpen);
			}else{
				setisOpen(isOpen);
			}
		});
		const handleLoad = window.addEventListener("load", () => {
			if (window.innerWidth > 991) {
				setisOpen(!isOpen);
			}else{
				setisOpen(isOpen);
			}
		});
		return () => {
			window.removeEventListener("scroll", scrollCallBack);
			window.removeEventListener("resize", handleResize);
			window.removeEventListener("load", handleLoad);
		};
	}, []);
	return <>
		<Router>
		<Meta/>
			<header className="header-area" id="header-area">
				<div className="header-top bg-2">
					<div className="container">
						<div className="row">
							<div className="col-md-6 col-sm-8 col-12 header-top-left">
								<div className="marqueeText">
									<Marquee pauseOnHover="true" speed="40">{siteSetup.top_text}</Marquee>
								</div>
							</div>
							<div className="col-md-6 col-sm-4 col-12 header-top-right">
								<div className="header_social_icon">
									<ul>
										{socialData.map((socialVal, socialKey) => {
											if(socialVal.social_type=='1'){return <li><a href={socialVal.url} target="_blank"><i className="fab fa-facebook-f"></i></a></li>;}
											if(socialVal.social_type=='2'){return <li><a href={socialVal.url} target="_blank"><i className="fab fa-twitter"></i></a></li>;}
											if(socialVal.social_type=='3'){return <li><a href={socialVal.url} target="_blank"><i className="fab fa-linkedin-in"></i></a></li>;}
											if(socialVal.social_type=='4'){return <li><a href={socialVal.url} target="_blank"><i className="fab fa-google-plus-g"></i></a></li>;}
											if(socialVal.social_type=='5'){return <li><a href={socialVal.url} target="_blank"><i className="fab fa-youtube"></i></a></li>;}
										})}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="header-middle bg-2">
					<div className="container">
						<div className="row">
							<div className="col-md-3 logoDesktop">
								<div className="logo logoSection"><Link to="/"><img src="../logo/logo.png" width="175" height="71px" alt="Logo" /></Link></div>
							</div>
							<div className="col-md-9 col-12 middleAll">
								<div className="header-middle-right">
									<ul>
										<li>
											<div className="contact-icon">
												<i className="fab fa-skype"></i>
											</div>
											<div className="contact-info">
												<p>Skype Us</p>
												<span>{siteSetup.skype}</span>
											</div>
										</li>
										<li>
											<div className="contact-icon">
												<i className="fas fa-envelope"></i>
											</div>
											<div className="contact-info">
												<p>MAIL US</p>
												<span>{siteSetup.email}</span>
											</div>
										</li>
										<li>
											<div className="contact-icon">
												<i className="fas fa-phone-alt"></i>
											</div>
											<div className="contact-info">
												<p>PHONE US</p>
												<span>{siteSetup.phone}</span>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="myHeader" className="header-bottom header">
					<div className="container">
						<div>
							<div className="row">
								<div className="col-md-11 logo_desktop_hide">
									<div className="logo logoSection"><Link to="/"><img src="../logo/logo.png" width="175" height="71px" alt="Logo" /></Link></div>
								</div>
								<div className="col-md-1 menu_desktop_hide">
									<div className="menuSection" onClick={onClickMenu}><i className={`fas ${isOpen ? 'fa-times':'fa-bars'}`} id="menu-click"></i></div>
								</div>
								<div className="col-md-12 menuPart">
									<div id="menu-header" className="mainmenu" style={{display: isOpen ? 'inline' : 'none'}}>
										<ul id="navigation">
											<li className="logoSticky"><Link to="/"><img src="../logo/logoSticky.png" alt="Sticky Logo" /></Link></li>
											<li><NavLink to="/" className="selected">Home</NavLink></li>
											<li><NavLink to="/portfolio" className="selected">Portfolio <i className="fa fa-angle-down"></i></NavLink>
												<ul className="submenu">
													{technologyList.map((val, key) => {
														return <><li><NavLink to={`/portfolio/${val.id}`} className="">{val.name}</NavLink></li></>;
													})}
												</ul>
											</li>
											<li><NavLink to="/services" className="selected">Services</NavLink></li>
											<li><NavLink to="/about-us" className="selected">About Us</NavLink></li>
											<li><NavLink to="/client-speak" className="selected">Client Speak</NavLink></li>
											<li><NavLink to="/contact-us" className="selected">Contact Us</NavLink></li>
										</ul>
									</div>						
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
			<div className="App" id ="App">     
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route path="/contact-us" element={<Contactus />} />
					<Route path="/portfolio" element={<Projects />} />
					<Route path="/portfolio/:id" element={<Projects />} />
					<Route path="/services" element={<Services />} />
					<Route path="/client-speak" element={<Client />} />
					<Route path="/about-us" element={<About />} />
					<Route path="/details/:id" element={<Details />} />
				</Routes>
			</div>
			<footer id="footer-area">
				<div className="footer-top">
					<div className="container">
						<div className="row">
							<div className="col-md-3 col-sm-6 col-12 footerOne col wow fadeInUp" data-wow-delay=".1s">
								<div className="footer-widget footer-logo">
									<div className="logo logoSection"><Link to="/"><img src="../logo/logo_footer.png" width="175" height="71" alt="Logo" /></Link></div>
									<p>{siteSetup.footer_about}</p>
								</div>
							</div>
							<div className="col-md-4 col-sm-6 col-12 footerTwo col wow fadeInUp" data-wow-delay=".2s">
								<div className="footer-widget footer-menu">
									<h2>Useful Links</h2>
									<ul>
										<li><NavLink to="/portfolio">Portfolio</NavLink></li>
										<li><NavLink to="/services" className="selected">Service</NavLink></li>
										<li><NavLink to="/about-us" className="selected">About Us</NavLink></li>
										<li><NavLink to="/contact-us" className="selected">Contact Us</NavLink></li>
									</ul>
								</div>
							</div>
							<div className="col-md-2 col-sm-6 col-12 footerThree col wow fadeInUp" data-wow-delay=".4s">
								<div className="footer-widget quick-contact">
									<h2>Contact Us</h2>							
									<ul>
										<li><i className="fas fa-home"></i> {siteSetup.location}</li>
										<li><i className="fas fa-phone-alt"></i> {siteSetup.phone}</li>
										<li><i className="fas fa-envelope"></i> {siteSetup.email}</li>
										<li className="footer_social_icon">
											{socialData.map((socialVal, socialKey) => {
												if(socialVal.social_type=='1'){return <a href={socialVal.url} target="_blank"><i className="fab fa-facebook-f"></i></a>;}
												if(socialVal.social_type=='2'){return <a href={socialVal.url} target="_blank"><i className="fab fa-twitter"></i></a>;}
												if(socialVal.social_type=='3'){return <a href={socialVal.url} target="_blank"><i className="fab fa-linkedin-in"></i></a>;}
												if(socialVal.social_type=='4'){return <a href={socialVal.url} target="_blank"><i className="fab fa-google-plus-g"></i></a>;}
												if(socialVal.social_type=='5'){return <a href={socialVal.url} target="_blank"><i className="fab fa-youtube"></i></a>;}
											})}
										</li>
									</ul>
								</div>
							</div>
							<div className="col-md-3 col-sm-6 col-12 footerFour col wow fadeInUp" data-wow-delay=".4s">
								<div className="footer-widget">
									<h2>Get New Deal</h2>
									<div className="input-group newDeal">
										<input type="text" className="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="button-addon2"/>
										<button className="btn" type="button"><i className="far fa-envelope"></i></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footer-bottom text-center">
					<div className="container">
						<div className="row">
							<div className="col-12">
								All Copyright {siteSetup.site_name} 2016 - {currYear}
							</div>
						</div>
					</div>
				</div>
			</footer>
			<ScrollButton />
		</Router>
	</>;
}
export default App;
