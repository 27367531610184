import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Helmet } from "react-helmet";

function Meta() {
	// const path = window.location.pathname === '/' ?  `/index` : window.location.pathname;
	const path = window.location.href.split('/')[3] === '' ?  `index` : window.location.href.split('/')[3];
	//console.log(path);
	const [siteSetup, setSitesetupData] = useState([]);
	const [setPageDetails, setPageDetailsData] = useState([]);
	
	useEffect(()=> {
		Axios.get('/sitesetup/').then((response)=>{
			//console.log(response.data);
			setSitesetupData(response.data[0]);
		});
	}, []);
	useEffect(()=> {
		Axios.get(`/page/${path}`).then((response)=>{
			setPageDetailsData(response.data[0]);
		});
	}, [path]);
	return <>
		<Helmet>
			<title>{ `${ setPageDetails.title } | ${ siteSetup.site_name }` }</title>
			<meta name="description" content={setPageDetails.meta_description} />
			<meta name="keyword" content={setPageDetails.meta_keyword} />
		</Helmet>
	</>;
}

export default Meta;
