import React, { useState, useEffect } from "react";
import Axios from "axios";

function Contact() {
	const [siteSetup, setSitesetupData] = useState([]);
	
	const [contactName, setName] = useState('');
	const [contactEmail, setEmail] = useState('');
	const [contactPhone, setPhone] = useState('');
	const [contactComment, setComment] = useState('');
	const submitContact = () => {
		Axios.post("/contactinsert/", {
			contactName: contactName,
			contactEmail: contactEmail,
			contactPhone: contactPhone,
			contactComment: contactComment,
		}).then(() => {
			alert('successful insert');
		});
	};
	useEffect(()=> {
		Axios.get('/sitesetup/').then((response)=>{
			//console.log(response.data);
			setSitesetupData(response.data[0]);
		});
	}, []);
	return <>
		<section className="fullstackTop">
			<div className="container">
				<div className="row">
					<div className="col-md-12 col-sm-12 col-12">
						<div className="section-title text-center"><h2>Contact Us</h2></div>
					</div>
					<div className="col-md-8 col-sm-8 col-12 contactLeft">						
						<div className="form-group">
							<label>Name</label>
							<input type="text" name="contactName" onChange={(e) => {setName(e.target.value)}} className="form-control" placeholder="Name"/>
						</div>
						<div className="form-group">
							<label>Email address</label>
							<input type="email" name="contactEmail" onChange={(e) => {setEmail(e.target.value)}} className="form-control" placeholder="name@example.com"/>
						</div>
						<div className="form-group">
							<label>Phone No</label>
							<input type="text" name="contactPhone" onChange={(e) => {setPhone(e.target.value)}} className="form-control" placeholder="Phone No"/>
						</div>
						<div className="form-group">
							<label>Message</label>
							<textarea name="contactComment" onChange={(e) => {setComment(e.target.value)}} className="form-control" rows="3"></textarea>
						</div>
						<div className="submitBtn"><button onClick={submitContact} className="btn btn-primary">Submit</button></div>
					</div>
					<div className="col-md-4 col-sm-4 col-12 contactRight">
						<div className="contactUsRight">
							<p className="ourOffice"><i class="fas fa-globe-asia"></i>Our office</p>
							<p><i class="fas fa-home"></i><b>{siteSetup.site_name}</b><span>{siteSetup.location}</span></p>
							<p><i class="far fa-envelope"></i>{siteSetup.email}</p>
							<p><i class="fas fa-phone-alt"></i>{siteSetup.phone}</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default Contact;
