import React, { useState, useEffect } from "react";
import Axios from "axios";

function Services() {
	const [serviceList, setServiceList] = useState([]);
	
	const [visible, setVisible] = useState(3);
	
	const loadMoreItems = () => {
		setVisible((prevValue) => prevValue+3);
	};
	
	useEffect(()=> {
		Axios.get('/servicelist/').then((response)=>{
			//console.log(response.data);
			setServiceList(response.data);
		});
	}, []);
	
	return <>
		<section className="fullstackTop">
			<div className="container">
				<div className="row">
				
					<div className="col-md-12 col-sm-12 col-12">
						<div className="section-title text-center">
							<h2>Our Fullstack Devolopment Service</h2>
							<p>The majority have suffered alteration in some form, by injected humour, or randomised. by injected humour, or randomised.</p>
						</div>
					</div>
				</div>
				<div className="row fullstack_dsign">
					{serviceList.slice(0, visible).map((val, key) => {
						return <>
							<div className="col-md-4 col-sm-4 col-12 fullstack_bottom">
								<div className="fullstack_section">
									<img src={`../uploads/services/${val.filename}`} alt="2"/>
									<h6>{val.name}</h6>
									<p>{val.description}</p>
								</div>
							</div>
						</>;
					})}
				</div>
				<div className="row">
					{visible < serviceList.length && (
						<div className="col-md-12 col-lg-12 col-sm-12 col-12">
							<div className="showMore showMoreBtn"><button onClick={loadMoreItems}>Show More</button></div>
						</div>
					)}
				</div>
			</div>
		</section>
	</>;
}

export default Services;
