import React, { useState, useEffect } from "react";
import Axios from "axios";

//function About() {
const About = () => {
	const [getCms, getCmsData] = useState([]);
	const [getBlob, getBlobData] = useState([]);
	useEffect(()=> {
		Axios.get(`/getCms/1`).then((response)=>{
			getCmsData(response.data[0]);
			
			const buf = new Buffer(response.data[0].description.data).toString();
			getBlobData(buf);
			
			//console.log(response.data[0].description.data);
			//console.log(buf);
		});
	}, []);
	return <>
		<section className="fullstackTop">
			<div className="container">
				<div className="row">				
					<div className="col-md-12 col-sm-12 col-12 aboutUsPage">
						<div className="section-title text-center">
							<h2>{getCms.name}</h2>
						</div>
						<div dangerouslySetInnerHTML={{__html: getBlob, }} />
					</div>
				</div>
			</div>
		</section>
	</>;
}

export default About;
